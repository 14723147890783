.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal.success {
  border-top: 4px solid #4caf50;
}

.modal.error {
  border-top: 4px solid #f44336;
}

.modal.confirm {
  border-top: 4px solid #2196f3;
}

.modal.info {
  border-top: 4px solid #9e9e9e;
}

.modal-content {
  text-align: center;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-content p {
  margin: 15px 0;
  color: #666;
  line-height: 1.4;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 120px;
}

.modal.success button {
  background-color: #4caf50;
  color: white;
}

.modal.error button {
  background-color: #f44336;
  color: white;
}

.modal.confirm button:first-child {
  background-color: #f44336;
  color: white;
}

.modal.confirm button:last-child {
  background-color: #9e9e9e;
  color: white;
}

.modal.info button {
  background-color: #2196f3;
  color: white;
}

.modal button.delete-btn {
  background-color: #f44336;
  color: white;
}

.modal button.back-btn {
  background-color: #2196f3;
  color: white;
}

.modal button.finish-btn {
  background-color: #4caf50;
  color: white;
}

.modal button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.modal button:active {
  transform: translateY(0);
}

.timer-bar-container {
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  margin: 20px 0;
  border-radius: 2px;
  overflow: hidden;
}

.timer-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #f44336;
  transition: width 0.01s linear;
}

/* Animation types */
.slide .timer-bar {
  transition: width 0.01s linear;
}

.fade .timer-bar {
  transition: width 0.01s ease-in-out;
}

.bounce .timer-bar {
  transition: width 0.01s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.modal.error .timer-bar {
  background-color: #f44336;
}

.modal.success .timer-bar {
  background-color: #4caf50;
}

.modal button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
