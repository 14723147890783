.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 64, 128, 0.95);
  z-index: 1000;
  padding: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Container for navigation items */
.nav-items-container {
  padding-right: 10%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-logo {
  width: 60px;
  height: auto;
}

.nav-logo-container {
  display: flex;
  align-items: center;
}

.nav-logo-container .nav-link {
  margin-left: 10px;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-right: 10%;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  white-space: nowrap;
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #ffd700;
  transition: all 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
  left: 0;
}

.nav-link:hover {
  color: #ffd700;
}

.nav-link.active {
  color: #ffd700;
}

.export-file {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: large;
}

/* Mobile menu toggle button */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 2.5rem;
  margin-left: 1rem;
  z-index: 1002;
}

/* Hamburger icon */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  padding: 8px;
  background: none;
  border: none;
  z-index: 1001;
}

.hamburger span {
  width: 24px;
  height: 2px;
  background: white;
  transition: all 0.3s ease;
}

/* Large screens (1200px and up) */

@media screen and (min-width: 1200px) {
  .navbar {
    padding: 0.5rem 2rem;
  }
  .nav-items-container {
    padding-right: 15%;
  }

  .nav-link {
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
  }
}

/* Medium screens (768px to 1199px) */
@media screen and (max-width: 1199px) {
  .nav-items-container {
    padding-right: 15%;
  }
}

/* Tablet and mobile screens */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0.75rem 1rem;
  }

  .nav-logo-container {
    padding-left: 5%;
    z-index: 1001;
    position: relative;
  }

  /* Hide main logo when mobile menu is open */
  .nav-links.open + .nav-logo-container {
    display: none;
  }

  .nav-items-container {
    padding-right: 5%;
    background-color: #000022;
  }

  .mobile-menu-toggle {
    display: block;
    position: absolute;
    right: 3rem;
    z-index: 1001;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #000022;
    padding: 5rem 1rem 2rem;
    gap: 1.5rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.3s ease;
    z-index: 1000;
    width: 100vw;
    height: auto;
    min-height: fit-content;
  }

  .nav-links.open {
    transform: translateY(0);
    opacity: 1;
  }

  .mobile-nav-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background: rgba(0, 64, 128, 0.95);
    display: flex;
    align-items: center;
    padding: 0 1rem;
    z-index: 1001;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    padding: 1rem;
    font-size: 1.1rem;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }

  /* Profile menu adjustments for mobile */
  .profile-menu {
    position: relative;
    top: 0;
    background: none;
    box-shadow: none;
    width: 100%;
    animation: none;
  }

  .profile-menu-item {
    color: white;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .profile-menu-item:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  /* Connection limits adjustments for mobile */
  .connection-limits {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .add-account-btn,
  .instructions-btn {
    width: 100%;
    justify-content: center;
  }

  .mobile-only {
    display: block;
  }

  .logout-btn-navbar {
    display: none; /* Hide the navbar logout button on mobile */
  }
}

/* Small mobile screens */
@media screen and (max-width: 480px) {
  .navbar {
    padding: 0.8rem;
  }

  .nav-logo {
    width: 50px;
  }

  .nav-link {
    font-size: 1rem;
    padding: 0.75rem;
  }
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background: #000070;
    padding: 5rem 1rem 2rem;
    gap: 1.5rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.3s ease;
    z-index: 1000;
  }

  .nav-links.open {
    transform: translateY(0%);
    opacity: 1;
  }

  .mobile-menu-toggle {
    font-size: 1.3rem;
    padding: 0.2rem;
    margin-right: 1.5rem;
  }
}

/* Extra small mobile screens */
@media screen and (max-width: 360px) {
  .navbar {
    padding: 0.7rem;
  }
  .nav-logo {
    width: 40px;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background: #000070;
    padding: 5rem 1rem 2rem;
    gap: 1.5rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.3s ease;
    z-index: 1000;
  }

  .nav-links.open {
    transform: translateY(0%);
    opacity: 1;
  }
}

.mobile-only {
  display: none;
}

.logout-btn-navbar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.logout-btn-navbar:hover {
  color: #ffd700;
}

.nav-right {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 1001;
}

.profile-menu {
  position: absolute;
  top: calc(100% + 0.5rem); /* Position right below the profile button */
  right: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in-out;
  overflow: hidden;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease;
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: left;
  background: none;
}

.profile-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .profile-menu {
    position: relative;
    top: 0;
    background: none;
    box-shadow: none;
    width: 100%;
    animation: none;
  }

  .profile-menu-item {
    color: white;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .profile-menu-item:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.Navbar-modal-overlay {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.Navbar-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
