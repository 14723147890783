.contact-container {
  padding: 2rem;
  max-width: 800px;
  margin: 5em auto;
  background: #ffffff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-container h1 {
  color: #004080;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #004080;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

.submit-btn {
  padding: 0.75rem 1.5rem;
  background: #004080;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: #003366;
}

/* Responsive */
@media (max-width: 768px) {
  .contact-container {
    padding: 1rem;
  }

  .contact-container h1 {
    font-size: 2rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .submit-btn {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-container h1 {
    font-size: 1.75rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.85rem;
  }

  .submit-btn {
    font-size: 0.9rem;
  }
}
