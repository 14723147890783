.terms-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background: #ffffff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
}

.terms-container h1,
.terms-container h2,
.terms-container h3 {
  color: #004080;
}

.terms-container h1 {
  margin: 3rem 0;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.terms-container h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.terms-container h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.terms-container p,
.terms-container ul {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.terms-container ul {
  padding-left: 1.5rem;
}

.terms-container li {
  margin-bottom: 0.5rem;
}

.red-text {
  color: red;
}

/* responsive */
@media (max-width: 768px) {
  .terms-container {
    padding: 1rem;
    margin-bottom: 3em;
  }

  .terms-container h1 {
    font-size: 2rem;
    margin: 3.5rem 0;
    margin-bottom: 5.5rem;
  }

  .terms-container h2 {
    font-size: 1.5rem;
  }

  .terms-container h3 {
    font-size: 1.25rem;
  }

  .terms-container p,
  .terms-container ul {
    font-size: 0.9rem;
  }

  .terms-container ul {
    padding-left: 1rem;
  }
}

@media (max-width: 480px) {
  .terms-container h1 {
    font-size: 2rem;
    margin: 3.5rem 0;
  }

  .terms-container {
    margin-bottom: 4em;
  }
}
