.container {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  color: rgba(10, 46, 94, 255);
}

.h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: rgba(10, 46, 94, 255);
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(10, 46, 94, 255);
}

.select,
.dateInput,
.button {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: rgba(10, 46, 94, 255);
}

.button {
  background-color: #d6dbe1;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #0056b3;
}

.confirmationMessage {
  color: green;
  font-weight: bold;
  margin-top: 1rem;
}

.disclaimer {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.disclaimerNote {
  color: rgb(176, 60, 60);
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.success {
  color: green;
  font-weight: bold;
  margin-top: 20px;
}

.exportButton {
  background-color: #ccc; /* Default color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
  transition: background-color 0.3s ease;
  width: 100%;
}

.exportButton.active {
  background-color: #28a745; /* Active color */
  cursor: pointer;
}

.errorNote {
  color: #dc2626; /* Red color for error */
  font-size: 0.75rem; /* Small font size */
  margin-top: 0.25rem;
  display: block;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.customMessage {
  animation: slideDown 1s ease-out forwards;
  position: fixed;
  top: 1.2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: rgba(255, 0, 0);
  color: white;
  text-align: center;
  padding: 15px;
  font-weight: bold;
  z-index: 1000;
  width: 60%;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
