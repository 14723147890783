.settingsPageContainer {
  min-height: 80vh;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png");
  background-size: cover, cover;
  background-blend-mode: overlay;
  padding: 20px;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 5.4rem;
  margin-bottom: 3rem;
  background-color: var(--mainColor);
}

.settingsListContainer {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}

.settingsCloseBtn {
  background-color: #c8a8a8;
  border: 1px solid #bb0c0c;
  color: #bb0c0c;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.settingsCloseBtn:hover {
  background-color: #bb0c0c;
  color: #fff;
}

h2 {
  text-align: center;
  color: #ffffff;
}

/* class="viewSettingsPage_settingsItem__WSuZw" make the with 40px */
.settingsItem > * {
  flex: 1;
}

.settingsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

li > .inputField {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 40%;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

li > .inputField:focus {
  outline: none;
  border: 1px solid #5a5cd9;
}

.settingsListContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  font-weight: 550;
}

/* .settingsListContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #333;
} */

label {
  flex: 1;
  margin-right: 10px;
  color: #555;
}

/* Slider toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
  width: 40px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #5a5cd9;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.saveButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #5a5cd9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.saveButton:hover {
  background-color: #0056b3;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
