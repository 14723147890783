:root {
  --mainColor: rgba(0, 64, 128, 0.55);
}

.instructionsContainer {
  padding: 2rem;
  max-width: 70%;
  margin: 5rem auto 6rem;
  background-color: var(--mainColor);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selectionContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.selectionButton {
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  border-radius: 6px;
  font-weight: 500;
  min-width: 200px;
}

.selectionButton:hover {
  background-color: #e9e9e9;
  transform: translateY(-2px);
}

.selected {
  background-color: #004080;
  border-color: #004080;
  color: white;
}

.selected:hover {
  background-color: #003366;
}

.instructionsList {
  list-style-type: decimal;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.instructionsList li {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.instructionsList li:hover {
  transform: translateY(-2px);
}

.imageInstructions {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.imageInstructions:hover {
  transform: scale(1.02);
}

.importantNote {
  font-weight: 400;
  color: red;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
}

.accountLinks {
  margin: 2rem 0;
}

.accountIdContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accountId {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.accountId:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.copyIcon {
  font-size: 1.2rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.accountId:hover .copyIcon {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .instructionsContainer {
    padding: 1rem;
    margin: 4rem 1rem 5rem;
  }

  .selectionContainer {
    flex-direction: column;
    align-items: center;
  }

  .selectionButton {
    width: 100%;
    max-width: 300px;
  }

  .instructionsList li {
    padding: 1rem;
  }
}
