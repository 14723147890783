html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("./components/assets/pattern.png"); /* Correct the import path */
  background-size: cover, cover;
  background-blend-mode: overlay;
  background-color: rgb(240, 240, 240, 0.5);
  color: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 64, 128, 0.8); /* Match the gradient colors */
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-link {
  color: #ffd700;
  text-decoration: none;
  margin: 0 1rem;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s;
}

.home-link {
  background: none;
  border: none;
  color: #ffffff !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  display: block;
  margin-top: 40px; /* Adjust the margin-top to scroll a bit before reaching the link */
  right: 0;
  background: #ffd700;
  transition: width 0.3s ease, right 0.3s ease;
}

.nav-link:hover::after {
  width: 90%;
  right: 0;
}

.nav-link:hover {
  color: #ffffff;
}

.nav-link i {
  margin-right: 0.5rem;
}

.nav-logo {
  height: 50px !important;
  width: 50px !important;
  margin-right: 1rem;
  border-radius: 20% !important;
  background-color: #ffffff;
}

.nav-logo:hover {
  border: 1px solid #ffffff;
}

.logout-btn-navbar {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s;
}

.logout-btn-navbar:hover {
  color: #ffd700;
}

.header-center {
  text-align: center;
  padding: 2rem 0;
  background-color: transparent; /* Make header background transparent */
}

.header-text {
  font-size: 2rem;
  color: #ffd700; /* Match the text color with the site's theme */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hero {
  text-align: center;
  margin-bottom: 2rem;
}

.cta {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #ffd700;
  color: #004080; /* Match the button text color with the site's theme */
  text-decoration: none;
  border-radius: 5px;
  margin-top: 1rem;
}

.cta:hover {
  background-color: #e6c200;
}

.features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.feature {
  max-width: 300px;
  text-align: center;
}

.pricing {
  text-align: center;
  margin-bottom: 2rem;
}

.pricing-content {
  margin-top: 0.5rem;
}

.pricing ul {
  list-style: none;
  padding: 0;
}

.pricing li {
  margin: 0.5rem 0;
}

footer {
  text-align: center;
  padding: 1rem 0;
  background-color: rgba(
    0,
    64,
    128,
    0.8
  ); /* Match the footer background color */
  color: white;
}

.nav-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logout-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}

.logout-btn:hover {
  color: #ffd700;
}

.profile-container {
  position: relative;
}

.profile-btn {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s;
}

.profile-btn:hover {
  color: #ffd700;
}

.profile-btn svg {
  margin-right: 8px;
}

.profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  min-width: 200px;
  margin-top: 0.5rem;
  z-index: 1000;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: #374151;
  text-decoration: none;
  width: 100%;
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-menu-item:hover {
  background-color: #f3f4f6;
}

.profile-menu-item svg {
  margin-right: 8px;
}

.profile-menu-item.logout-btn {
  color: #dc2626;
}

.profile-menu-item.logout-btn:hover {
  background-color: #fef2f2;
}

.profile-menu-item.logout-btn:hover svg {
  color: inherit;
}

.nav-center {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.connection-limits {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
}

.add-account-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.add-account-btn:hover {
  background: #ffffff;
  color: rgba(0, 64, 128, 0.8);
}

.excel-instructions {
  position: relative;
}

.instructions-btn {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.instructions-btn:hover {
  background: #ffffff;
  color: rgba(0, 64, 128, 0.8);
}

.url-section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.98);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
}

.instruction-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  max-height: 80vh;
  overflow-y: auto;
}

.instruction-images img {
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
