.delete-account-container {
  max-width: 600px;
  margin: 4rem auto;
  padding: 2rem;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.delete-account-title {
  color: #d32f2f;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.warning-box {
  background-color: #fff3e0;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  border: 1px solid #ffb74d;
  color: #e65100;
}

.info-section {
  margin-bottom: 2rem;
}

.info-section ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.info-section li {
  margin-bottom: 0.5rem;
  color: #333;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.checkbox-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkbox-container span {
  color: #333;
  font-size: 0.95rem;
}

.error-message {
  background-color: #ffebee;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border: 1px solid #ef5350;
  color: #c62828;
}

.delete-button {
  background-color: #d32f2f;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #b71c1c;
}

.delete-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.deleting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
}

.deleting-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d32f2f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-top: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not-logged-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.not-logged-in-container .error-message {
  background-color: #ffebee;
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid #ef5350;
  color: #c62828;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  max-width: 400px;
  font-family: "Roboto", sans-serif;
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
