.infoContainer {
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px; /* Padding around the container */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 600px; /* Max width for the container */
  margin: 0 auto; /* Center the container */
  font-weight: 500; /* Bold text */
}

.infoHeader {
  font-size: 26px; /* Font size for the header */
  color: #333; /* Text color */
  text-align: center; /* Center align the header */
  margin-top: 20px; /* Space above the header */
  margin-bottom: 30px; /* Space below the paragraph */
}

.infoParagraph {
  font-size: 22px; /* Font size */
  color: #333; /* Text color */
  line-height: 1.8; /* Line height for better readability */
  font-family: Arial, sans-serif; /* Font family */
  margin-bottom: 28px; /* Space between bullet points */
}

.infoOnOrededList {
  margin-top: 10px; /* Space above the list */
  font-size: 22px; /* Font size */
  color: #333; /* Text color */
  line-height: 1.8; /* Line height for better readability */
  padding-left: 40px; /* Indent bullet points */
}

.infoOnOrededList ul {
  padding-left: 40px; /* Indent bullet points */
  list-style-type: square; /* Bullet point style */
}

.infoOnOrededList li {
  margin-bottom: 10px; /* Space between bullet points */
}

.FinnishingStatement {
  font-size: 25px; /* Font size */
  color: #333; /* Text color */
  line-height: 1.5; /* Line height for better readability */
  margin-top: 35px; /* Space above the text */
  font-weight: 750; /* Bold text */
  text-align: center; /* Center align the text */
}
