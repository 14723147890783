.loginPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png");
  background-size: cover, cover;
  background-blend-mode: overlay;
  background-color: rgb(240, 240, 240, 0.5);
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  margin: 0 auto; /* Center horizontally */
  margin-top: 1rem;
  max-width: 1200px; /* Maximum width */
}

.loginBox {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

/* mobile sizes */
@media (max-width: 768px) {
  .loginBox {
    padding: 1rem;
  }
}
