.bank-list-page {
  min-height: 83vh;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png");
  background-size: cover, cover;
  background-blend-mode: overlay;
  padding: 20px;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 5.4rem;
  margin-bottom: 3rem;
  background-color: var(--mainColor);
}

.pageName {
  color: #ffffff;
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0;
}

.mainBox {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.bank-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
}

.bank-item {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  transition: all 0.2s;
  margin-bottom: 10px;
}

.bank-item:hover {
  border-color: #2563eb;
}

.bank-item input[type="radio"] {
  margin-right: 10px;
}

.bank-item label {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.bank-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 1rem;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
}

.bank-details:hover {
  background-color: #e9e9e9;
}

.bank-name-bic {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.bank-name {
  font-weight: 500;
  color: #111827;
}

.bank-bic {
  font-size: 0.875rem;
  color: #6b7280;
}

.bank-logo-box {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  height: 40px;
  width: auto;
}

.bank-item.selected {
  background-color: #e0e0ff;
  border-color: #0000ff;
}

.backToLoginBtn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.backToLoginBtn:hover {
  background-color: #e0dede;
}

.goToAccountsBtn {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
  margin: 1rem auto;
  display: block;
}

.goToAccountsBtn:hover {
  background: #ffffff;
  color: rgba(0, 64, 128, 0.8);
}

.country-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin: 1rem 0;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.errorMessage {
  background-color: #ffdddd;
  color: #d8000c;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  text-align: center;
  position: relative;
  backdrop-filter: blur(10px);
}

.errorCloseBtn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  color: #d8000c;
  cursor: pointer;
  font-size: 1.5rem;
}

.searchBank {
  margin: 2rem 0;
  margin-top: 20px;
}

.searchBank-Title {
  font-size: 1.2rem;
  color: #374151;
  margin-bottom: 10px;
}

.searchBank-searchBox {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  border: 1px solid #ccc;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 64, 128, 0.8);
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-logo {
  height: 40px;
}

.nav-link {
  color: #ffd700;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ffffff;
}

.logout-btn-navbar {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s;
}

.logout-btn-navbar:hover {
  color: #ffd700;
}
