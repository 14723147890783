* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2563eb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header {
  background: -webkit-linear-gradient(
    135deg,
    rgba(58, 123, 213, 0.8) 0%,
    rgba(0, 210, 255, 0.8) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(58, 123, 213, 0.8) 0%,
    rgba(0, 210, 255, 0.8) 100%
  );
  background: -o-linear-gradient(
    135deg,
    rgba(58, 123, 213, 0.8) 0%,
    rgba(0, 210, 255, 0.8) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(58, 123, 213, 0.8) 0%,
    rgba(0, 210, 255, 0.8) 100%
  );
}
