body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.signupPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png");
  background-size: cover, cover;
  background-blend-mode: overlay;
  background-color: rgb(240, 240, 240, 0.5);
}

.signupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 150px);
  width: 100%;
}

.signupBox {
  background: rgba(255, 255, 255, 0.95);
  width: 40%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
  padding: 2rem;
}

.logoSection {
  text-align: center;
  margin-bottom: 2rem;
}

.logoSection h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.subtitle {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.signupForm {
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.formGroup label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-top: 0.8rem;
}

.input {
  width: 100%;
  padding: 0.625rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.9);
}

.input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.approvalCodeContainer {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.signupButton {
  width: 100%;
  padding: 0.625rem 1rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.signupButton:hover {
  background-color: #1d4ed8;
}

.signupButton:disabled {
  background-color: #9ca3af; /* Gray color for disabled state */
  cursor: not-allowed;
  opacity: 0.7;
}

.signinLink {
  text-align: center;
  margin-top: 1rem;
}

.signinLink a {
  color: #2563eb;
  font-size: 0.875rem;
  text-decoration: none;
}

.signinLink a:hover {
  text-decoration: underline;
}

.errorMessage,
.responseMessage {
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  text-align: center;
}

.errorMessage {
  background-color: rgba(254, 242, 242, 0.9);
  color: #dc2626;
  border: 1px solid #fecaca;
}

.responseMessage {
  background-color: rgba(240, 253, 244, 0.9);
  color: #16a34a;
  border: 1px solid #bbf7d0;
}

.termsContainer {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: #374151;
  line-height: 1.5;
}

.termsLabel {
  align-items: flex-start;
  gap: 0.5rem;
}

.termsLabel input {
  margin-top: 0.2rem;
}

.termsLabel a {
  color: #2563eb;
  text-decoration: underline;
}

.termsLabel a:hover {
  color: #1d4ed8;
}

@media screen and (max-width: 1023px) {
  .signupPage {
    width: 100%;
  }
  .signupBox {
    flex-direction: column;
    box-shadow: none;
    width: 100%;
  }
  .signupContainer {
    width: 70%;
  }
  .illustrationWrapper img {
    width: 100%;
    min-height: auto;
    margin: 0 auto;
  }

  .signupForm {
    width: 80%;
    margin-top: 1rem;
    padding: 0.5rem;
  }
}

/* for mobile devices*/
@media screen and (max-width: 768px) {
  .signupContainer {
    padding: 1rem;
    justify-content: center;
    min-height: calc(100vh - 120px);
    margin: 0 auto;
    width: 100%;
  }

  .signupForm {
    width: 80%;
  }
}

@media screen and (max-width: 380px) {
  .signupBox {
    width: 100%;
    margin: 0 auto;
  }
  .signupContainer {
    width: 100%;
  }
  .signupForm {
    width: 80% !important;
    margin: 0 auto;
    padding-top: 0;
  }
}
