.footer {
  background-color: #000022;
  color: white;
  padding: 0.2rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  background: linear-gradient(
    135deg,
    #001f3f 0%,
    #003366 35%,
    #004080 65%,
    #001f3f 100%
  );
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start; /* Align items to the start (left) */
  align-items: center;
}

.footerNav {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-left: auto; /* Align links to the right */
}

.footerNav a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
  font-size: 0.9rem;
}

.footerNav a:hover {
  color: #ffd700;
}

.footerNav a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #ffd700;
  transition: width 0.3s ease;
}

.footerNav a:hover::after {
  width: 100%;
}

.footerContent p {
  display: inline-block !important;
  font-size: 1rem;
  margin: 0;
  float: none; /* Remove float to allow flex alignment */
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0.3rem 0;
  }

  .footerContent {
    flex-direction: column;
    text-align: center;
    gap: 0.3rem;
    padding: 0 1rem;
  }

  .footerContent p {
    font-size: 0.8rem;
    margin: 0;
  }

  .footerNav {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-left: 0; /* Reset margin for smaller screens */
  }

  .footerNav a {
    font-size: 0.8rem;
    padding: 0.25rem;
  }

  .footerNav a::after {
    bottom: -2px;
  }
  .footerText p {
    font-size: 0.9rem;
    margin: 0 1rem;
    float: left !important;
  }
}

/* For very small screens */
@media screen and (max-width: 380px) {
  .footerNav {
    gap: 0.3rem;
  }

  .footerNav a {
    font-size: 0.75rem;
  }
}
