.main {
  margin: 20px 2rem; /* Center the container */
  margin-top: 5rem;
  padding: 25px;
  color: #2c3e50; /* Dark blue-gray text for better readability */
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add spacing between child elements */
}

.mainContainerBankAccounts {
  margin-bottom: 20px;
}

.bankAccountsContainer {
  display: flex;
  flex-direction: row;
  gap: 40px; /* Add spacing between child elements */
  flex: 1;
  overflow-y: auto; /* Add vertical scrollbar */
  max-height: 80vh; /* Set a maximum height for the container */
  scrollbar-width: thin; /* Thin scrollbar */
  scroll-behavior: smooth; /* Smooth scrolling */
  padding: 15px; /* Add padding for content spacing */
  transition: all 0.3s ease; /* Smooth transition for size changes */
  flex-wrap: nowrap; /* Prevent wrapping to the next row */
  gap: 20px; /* Add spacing between cards */
  align-items: flex-start; /* Align cards at the top */
  padding: 20px; /* Add padding for spacing */
  overflow-x: hidden; /* Hide the horizontal scrollbar */
  padding-left: 0; /* Ensure no left padding */
  margin-left: 0; /* Ensure no left margin */
  position: relative; /* Ensure proper stacking context */
  z-index: 1; /* Place below the scroll buttons */
}

.bankAccountsContainer.expanded {
  flex: 0.5; /* Reduce the size when an account card is open */
  max-height: 40vh; /* Reduce the maximum height */
  opacity: 0.8; /* Slightly reduce opacity */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow */
}

.bankAccountsContainer.expandedContainer {
  z-index: 1; /* Place below the arrows */
}

.mainContainerBankAccountsDetails {
  flex: 2;
  overflow-y: auto; /* Add vertical scrollbar */
  max-height: 80vh; /* Set a maximum height for the container */
  scrollbar-width: thin; /* Thin scrollbar */
  scroll-behavior: smooth; /* Smooth scrolling */
}

.expandedDetailsContainer {
  flex: 2;
  overflow-y: auto;
  max-height: 80vh;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  background-color: #ffffff; /* White background for contrast */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Add padding for content spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.container {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow for a modern look */
  padding: 20px;
  margin: 20px;
  background-color: #ffffff; /* White background for a clean look */
  border: 1px solid #e0e0e0; /* Light border for separation */
}

.container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  transform: translateY(-3px); /* Slight lift effect */
  transition: all 0.2s ease-in-out; /* Smooth transition */
  background-color: #f9f9f9; /* Slightly darker background on hover */
}

.removeAccountBtn {
  background-color: #ff6b6b; /* Softer red for remove button */
  color: #ffffff; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 5px; /* Some padding */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Transition for hover effect */
  font-size: 0.6rem; /* Smaller font size */
  font-weight: bold; /* Bold text */
  margin: 0.5rem; /* Margin for spacing */
  text-align: center; /* Centered text */
  width: 100%; /* Full width */
  max-width: 150px; /* Maximum width */
  align-items: center; /* Center vertically */
  text-decoration: none; /* Remove underline from text */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 1px; /* Spacing between letters */
}

.removeAccountBtn:hover {
  background-color: #e63946; /* Slightly darker red on hover */
}

.title {
  color: #2c3e50; /* Dark blue-gray for titles */
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font: 1.2rem sans-serif;
  font-size: 100%;
}

/* Media query for devices wider than 1100px */
@media (min-width: 1200px) {
  .title {
    font-size: 1.5rem; /* Increase the font size */
  }
}

.header {
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  background: linear-gradient(to right, #4a90e2, #50b3a2); /* Softer gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* messagePositive green style and droped down and bouncing twice */
.messagePositive {
  width: 50%;
  color: #155724;
  font-size: 1.2rem;
  text-align: center;
  background-color: #d4edda;
  border: #c3e6cb 1px solid;
  margin: 0 auto;
  animation: bounce 0.5s 2;

  /* Add animation */
  animation-name: dropDown;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}

.errorMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: #721c24; /* Dark red text for errors */
  font-size: 1.2rem;
  text-align: center;
  background-color: #f8d7da; /* Light red for error background */
  border: #f5c6cb 1px solid; /* Matching border color */
  margin: 6rem auto;
}
.errorMessage > * {
  margin: 15px 10px; /* Add margin to child elements for spacing */
}

.errorCloseBtn {
  background-color: #f5c6cb; /* Light red for close button */
  border: 1px solid #bb0c0c;
  color: #721c24; /* Dark red text */
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.errorCloseBtn:hover {
  background-color: #e63946; /* Darker red on hover */
  color: #ffffff; /* White text on hover */
}

.headerBox {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-end;
  justify-content: space-around;
  align-items: center;
}

.connectionsAmount {
  background-color: #5a5cd9;
  border: 1px solid #5a5cd9;
  color: rgb(0, 58, 112);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 0.5rem;
}

.connectionsAmount:hover {
  background-color: #04103b;
  color: #341ac7;
}

/* Mobile styles */
@media (max-width: 768px) {
  .headerBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0;
  }
}

.requisitionContainer {
  display: flex;
  margin: auto auto 20px auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #5a5cd9;
}

.imageInstructions {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5a5cd9;
  width: 60%;
}

.accountIds {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #5a5cd9;
}

.accountId {
  cursor: pointer;
  color: #2980b9;
  border: #04103b 1px solid;
  border-radius: 5px;
  padding: 5px;
  transition: background-color 0.3s ease;

  /* Add hover effect */
  &:hover {
    background-color: #04103b;
    color: #fff;
  }
}

.allInOneSheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a5cd9;
  list-style: none;
  padding: 0;
  border: #04103b 1px solid;
  border-radius: 5px;
  padding: 5px;
  transition: background-color 0.3s ease;

  /* Add hover effect */
  &:hover {
    background-color: #04103b;
    color: #fff;
  }
}

.requisitionLength {
  color: #4f0800;
  font-size: 1.2rem;
  background-color: #ddbdb8;
  /*  border: #c0392b 1px solid; */
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
}

.requisitionInfo {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin: 10px 0;
  align-items: start;
}

.requisitionLimitReached {
  color: #7b0c00;
  font-size: 1.2rem;
  text-align: center;
  background-color: #dbb2ac;
  border: #c0392b 1px solid;
  margin: 0 auto;
}

.requisitionLimit {
  color: #7b0c00;
  font-size: 1.2rem;
  background-color: #dbb2ac;
  border: #c0392b 1px solid;
  margin: 0 auto;
}

.bankHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a5cd9;
}

.BankAccountsDataContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  background-color: #e9e9ea;
}

.bankData {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-evenly;
  width: 95%;
  padding: 20px;
  margin: 0 auto;
}

.bankData span {
  font-weight: 650;
}

.BankAccountsDataContainer strong {
  color: rgb(0, 58, 112);
}

.logo {
  width: 100px;
  height: 100px;
  border-radius: 25%;
  margin-right: 20px;
  float: right;
}

.accountList {
  list-style: none;
  padding: 0;
}

.accountItem {
  /* background-color: #ecf0f1; */
  color: #003a70;
  margin: 0.75px 0;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accountBicName {
  display: flex;
  flex-direction: column;
  background-color: #a3c8e2;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  line-height: 2;
}

.accountBicName {
  font-family: Arial, sans-serif;
  padding: 10px;
  background-color: #a3c8e2;
  border-radius: 5px;
  margin: 10px 0;
}

.accountDetails {
  margin: 10px 0;
}

.balanceRow {
  margin: 5px 0;
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 3px;
  font-weight: 650;
  color: #003a70;
}

.balanceRow strong {
  margin-right: 5px;
}

.accountTitle {
  margin: 0 0 10px;
  color: #0c2a3e;
}

.expanded {
  background-color: #a3c8e2;
}

.expandedDetails {
  margin-top: 10px;
  padding: 10px;
  background-color: #a3c8e2;
  border-radius: 5px;
}

.transactionTable {
  width: 65%; /* Use 100% width for better adaptiveness */
  border-collapse: collapse;
  font-size: 0.9rem; /* Adjust font size as needed */
  font-weight: 400;
  margin: auto auto 20px auto;
}

.transactionTable th,
.transactionTable td {
  border: 1px solid #a3c8e2;
  padding: 4px 16px;
  text-align: left;
}

.subTitle {
  margin-top: 15px;
  color: #003a70;
}

.additionalInfo {
  margin-top: 10px;
  color: #003a70;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.additionalInfoDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
/* every second style defrrent */
.additionalInfoDetails:nth-child(odd) {
  color: #04270e;
}

.additionalInformationBtn {
  color: #04103b;
  padding: 6px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: underline;
}

.btnForTransactions {
  color: #ffffff; /* White text */
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: underline;
  background-color: #4a90e2; /* Blue for transaction buttons */
  /* offset color */
  outline-style: solid;
  outline-color: #04103b;
  outline-width: 1px;
  /* shaddow */
  box-shadow: 0 0 10px #04103b;
}

.ibanBAnkSearch {
  display: flex;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: underline;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-between;
}

.searchTransactionInput {
  justify-content: right;
  padding: 8px;
  width: 18rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* offset color */
  outline-style: solid;
  outline-color: #04103b;
  outline-width: 1px;
  /* shaddow */
  box-shadow: 0 0 10px #04103b;
}

.btnForTransactions:hover {
  background-color: #357ab8; /* Darker blue on hover */
}

/* change the style for mobile */
@media (max-width: 768px) {
  .mainContainer {
    width: 100%;
    padding: 0;
  }

  .bankData {
    width: 100%;
    /* shrink */
    padding: 10px;
    font-size: 12px;
  }

  .accountItem {
    padding: 4px;
    font-size: 12px;
  }

  .transactionTable {
    width: 100%;
    /* scroll side ways while over lapping */
    overflow-x: auto;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .additionalInfo {
    flex-direction: row;
    background-color: #e9e9ea;
  }

  .expanded {
    background-color: #c3c5d0;
  }

  .expandedDetails {
    background-color: #e9e9ea;
  }

  .transactionTable {
    width: 100%;
    font-size: 8px;
  }

  .ibanBAnkSearch {
    /* col */
    flex-direction: column;
    padding: 8px;
    width: 100%;
  }
  .searchTransactionInput {
    width: 100%;
    margin-top: 0.5rem;
  }

  .errorMessage {
    margin: 6rem auto;
    flex-direction: column;
    background-color: #f8d7da; /* Light red for error background */
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .main {
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow for smaller screens */
  }

  .mainContainer {
    flex-direction: column; /* Stack elements vertically */
    gap: 15px; /* Adjust spacing for smaller screens */
  }

  .bankAccountsContainer,
  .expandedDetailsContainer {
    max-height: 60vh; /* Reduce height for smaller screens */
    padding: 10px; /* Adjust padding */
  }

  .navigationBar {
    margin-top: 3.5rem;
  }

  .mainContainer {
    flex-direction: column;
  }

  .bankAccountsContainer,
  .expandedDetailsContainer {
    flex: none;
    width: 100%;
  }

  .expandedDetailsContainer {
    flex: none;
    width: 100%;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100vh; /* Ensure it doesn't exceed the viewport height */
  }

  .container {
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    background-color: #f5f5f5; /* Light gray for containers */
  }

  .title {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .navbar {
    /* just make everything smaller */
    padding: 1px;
  }

  .logoutBtn {
    padding: 5px;
    height: 3.5rem;
    margin-left: 15px;
  }

  .logoutIcon {
    width: 30px;
  }
}

.reconnectBtn {
  background-color: #28a745; /* Green for reconnect button */
  color: #ffffff; /* White text */
  border-radius: 5px;
  padding: 10px 20px; /* Adjusted padding */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Added transform transition */
  margin-bottom: 0.5rem;
  font-size: 1rem; /* Added font size */
  font-weight: bold; /* Added font weight */
  text-align: center; /* Centered text */
}

.reconnectBtn:hover {
  background-color: #218838; /* Darker green on hover */
}

.reconnectBtn:active {
  background-color: #1e7e34; /* Even darker background on click */
  transform: scale(1); /* Reset scale on click */
}

.navigationBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  /* give me modern bank style for this */
  background-color: rgba(0, 64, 128, 0.923); /* Soft blue for back button */
  color: #ffffff; /* White text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for better touch target */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Transition for hover effect */
  font-size: 1rem; /* Font size for better readability */
  font-weight: bold; /* Bold text for emphasis */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline from text */
  text-transform: uppercase; /* Uppercase text for consistency */
  letter-spacing: 1px; /* Spacing between letters */
  margin: 0.5rem; /* Margin for spacing */
  width: 100%; /* Full width for better touch target */
  max-width: 150px; /* Maximum width for better layout */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.bankAccountHeader {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  align-items: center;
  cursor: pointer;
  color: #2c3e50; /* Dark blue-gray for headers */
  padding: 5px;
}

.bankLogo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border: 1px solid #cccccc; /* Add a border to the logo for better visibility */
}

.accountsList {
  display: flex; /* Use flex layout for dynamic resizing */
  flex-wrap: wrap; /* Allow wrapping of cards */
  gap: 15px; /* Add spacing between cards */
  justify-content: space-evenly; /* Distribute cards evenly */
  align-items: flex-start; /* Align cards at the top */
}

.accountCard {
  background: linear-gradient(
    135deg,
    #ffffff,
    #f9f9f9
  ); /* Subtle gradient for modern look */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 12px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  flex: 0 0 auto; /* Prevent cards from stretching to match the tallest card */
  scroll-snap-align: start; /* Ensure cards snap to the start */
  text-align: center; /* Center text for Add Account card */
  height: 180px; /* Ensure consistent height for all cards */
  margin-bottom: 2rem; /* Add margin for spacing */
}

/* Ensure cards with more content do not affect others */
.accountCard > * {
  margin-bottom: 10px; /* Add spacing between child elements */
}

.accountCard:hover {
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  background: linear-gradient(
    135deg,
    #f9f9f9,
    #ffffff
  ); /* Slightly brighter gradient on hover */
}

.accountHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start; /* Align content to the left for a clean layout */
}

.accountIban {
  font-size: 1rem;
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for IBAN */
  word-wrap: break-word; /* Ensure long IBANs wrap properly */
}

.accountBalance {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4a90e2; /* Blue for balance */
}

.accountBalance::before {
  content: "💰 "; /* Add an icon for visual appeal */
  font-size: 1.2rem;
}

.accountCard:hover .accountBalance {
  color: #357ab8; /* Darker blue on hover */
}

.accountCard:hover .accountIban {
  color: #1c3d5a; /* Darker shade for IBAN on hover */
}

/* Add animations for smooth transitions */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accountCard {
  animation: fadeIn 0.5s ease-in-out;
}

/* Add a subtle border glow effect on focus */
.accountCard:focus-within {
  outline: 2px solid #4a90e2; /* Blue outline for focus */
  outline-offset: 4px;
}

/* PC-specific styles */
@media (min-width: 1024px) {
  .accountsList {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Show 3 cards per row on larger screens */
  }

  .accountCard {
    margin-top: 0.8rem; /* Add margin for spacing */
    padding: 25px; /* Increase padding for better spacing */
  }

  .accountIban {
    font-size: 1.1rem; /* Slightly larger font for IBAN */
  }

  .accountBalance {
    font-size: 1.3rem; /* Slightly larger font for balance */
  }
}

.bankAccountCard {
  justify-content: space-between;
  background-color: #ffffff; /* White background for cards */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  flex: 1 1 300px; /* Allow the card to grow and shrink */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  transition: flex 0.3s ease, max-width 0.3s ease; /* Smooth transition for resizing */
}

@media (min-width: 1100px) {
  .bankAccountCard {
    flex: 1 1 400px; /* Increase the base width for larger screens */
  }
}

@media (min-width: 1400px) {
  .bankAccountCard {
    flex: 1 1 500px; /* Further increase the base width for very large screens */
  }
}

@media (min-width: 1800px) {
  .bankAccountCard {
    flex: 1 1 600px; /* Maximum width for ultra-wide screens */
  }
}

.bankAccountCard:hover {
  transform: none;
  box-shadow: none;
}

.bankAccountHeader {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.bankLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Circular logo */
  border: 1px solid #cccccc; /* Border for better visibility */
}

.bankDetails {
  display: flex;
  flex-direction: column;
}

.bankName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for bank name */
  margin: 0;
}

.totalBalance {
  font-size: 1.2rem;
  color: #4a90e2; /* Blue for total balance */
  margin: 5px 0 0;
}

.accountsList {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.accountCard {
  background-color: #f9f9f9; /* Light gray background for accounts */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 8px; /* Rounded corners */
  padding: 15px;
  width: 100%;
  max-width: 500px; /* Limit the width for better layout */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.accountCard:hover {
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.accountHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accountIban {
  font-size: 1rem;
  color: #2c3e50; /* Dark blue-gray for IBAN */
}

.accountBalance {
  font-size: 1.1rem;
  font-weight: bold;
  color: #4a90e2; /* Blue for balance */
}

.pageTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for the title */
  text-align: center;
  margin-bottom: 20px;
}

.bankAccountCard {
  justify-content: space-between;
  background: linear-gradient(135deg, #ffffff, #f9f9f9); /* Subtle gradient */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.bankAccountCard:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.bankAccountHeader {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.bankLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Circular logo */
  border: 2px solid #4a90e2; /* Blue border for emphasis */
}

.bankDetails {
  display: flex;
  flex-direction: column;
}

.bankName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for bank name */
  margin: 0;
}

.totalBalance {
  font-size: 1.2rem;
  color: #4a90e2; /* Blue for total balance */
  margin: 5px 0 0;
}

.accountsList {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.accountHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accountIban {
  font-size: 1rem;
  color: #2c3e50; /* Dark blue-gray for IBAN */
}

.accountBalance {
  font-size: 1.1rem;
  font-weight: bold;
  color: #4a90e2; /* Blue for balance */
}

.searchInput {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc; /* Light border */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 1rem;
}

/* Add animations for smooth transitions */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bankAccountCard,
.accountCard {
  animation: fadeIn 0.5s ease-in-out;
}

.expandedGroup {
  background-color: #f9f9f9; /* Light gray background for expanded group */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow for expanded group */
}

.expandedCard {
  background-color: #e6f7ff; /* Light blue background for expanded card */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow for expanded card */
  flex: 1 1 400px; /* Allow the card to grow dynamically */
  min-width: 400px; /* Set a minimum width */
  max-width: 600px; /* Set a maximum width */
  transition: flex 0.3s ease, min-width 0.3s ease; /* Smooth transition for resizing */
}

/* Add a subtle border glow effect on focus */
.accountCard:focus-within {
  outline: 2px solid #4a90e2; /* Blue outline for focus */
  outline-offset: 4px;
}

/* PC-specific styles */
@media (min-width: 1024px) {
  .accountsList {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Show 3 cards per row on larger screens */
  }

  .accountCard {
    margin-top: 1rem; /* Add margin for spacing */
    margin-bottom: 0.2rem; /* Add margin for spacing */
  }

  .accountIban {
    font-size: 1.1rem; /* Slightly larger font for IBAN */
  }

  .accountBalance {
    font-size: 1.3rem; /* Slightly larger font for balance */
  }
}

.closedCard {
  background-color: #ffffff; /* White background for closed cards */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 8px; /* Rounded corners */
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  width: 18rem; /* Fixed width for closed cards */
}

.closedCard:hover {
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.bankAccountCard {
  display: flex;
  flex-direction: row; /* Default to row layout */
  justify-content: space-between;
  background-color: #ffffff; /* White background for cards */
  border: 1px solid #e0e0e0; /* Light border for separation */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease; /* Smooth transition for size changes */
  cursor: pointer;
}

.bankAccountCard.shrinked {
  transform: scale(0.9); /* Slightly shrink the card */
  opacity: 0.7; /* Reduce opacity for a subtle effect */
  /* pointer-events: none; */ /* Disable interactions for non-expanded cards */
}

@media (max-width: 1000px) {
  .bankAccountCard {
    flex-direction: column; /* Change to column layout for smaller screens */
    align-items: flex-start; /* Align items to the start */
    gap: 15px; /* Add spacing between elements */
  }

  .accountCard {
    margin-top: 1rem; /* Add margin for spacing */
    height: 150px; /* Set a fixed height for cards */
    margin-bottom: 0.2rem; /* Add margin for spacing */
  }

  .bankLogo {
    width: 50px; /* Adjust logo size for smaller screens */
    height: 50px;
  }

  .bankDetails {
    flex: none; /* Reset flex behavior */
  }

  .totalBalance {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .accountCard {
    margin-top: 3rem; /* Add margin for spacing */
    height: 150px; /* Set a fixed height for cards */
  }
}

.expandedCard {
  flex: 1; /* Allow the card to grow equally */
  min-width: 300px; /* Set a minimum width */
  transition: flex 0.3s ease, min-width 0.3s ease; /* Smooth transition for resizing */
}

/* Adjust width for larger screens */
@media (min-width: 1200px) {
  .expandedCard {
    width: 800px !important; /* Increase the minimum width for larger screens */
  }
}

@media (min-width: 1400px) {
  .expandedCard {
    min-width: 500px; /* Further increase the minimum width for very large screens */
  }
}

@media (min-width: 1800px) {
  .expandedCard {
    min-width: 600px; /* Maximum width for ultra-wide screens */
  }
}

.institutionLogo {
  width: 50px; /* Set a fixed width for the logo */
  height: 50px; /* Set a fixed height for the logo */
  border-radius: 50%; /* Make the logo circular */
  margin-right: 10px; /* Add spacing between the logo and text */
  object-fit: cover; /* Ensure the logo fits within the bounds */
}

.navigationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2; /* Ensure arrows are above expanded cards */
}

.scrollButton {
  background-color: #4a90e2; /* Blue background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease;
  position: absolute; /* Ensure proper positioning */
  z-index: 3; /* Ensure buttons are always above cards */
  pointer-events: auto; /* Ensure buttons are clickable */
}

.scrollButton:hover {
  background-color: #357ab8; /* Darker blue on hover */
}

.scrollButton:first-of-type {
  left: 20px; /* Default position for larger screens */
}

@media (max-width: 768px) {
  .scrollButton:first-of-type {
    left: 10px; /* Narrower position for smaller devices */
  }
}

.scrollButton:last-of-type {
  right: 20px; /* Default position for larger screens */
}

@media (max-width: 768px) {
  .scrollButton:last-of-type {
    right: 10px; /* Narrower position for smaller devices */
  }
}

.dimmedCard {
  opacity: 0.7; /* Dim other cards */
  /* pointer-events: none; */ /* Disable interaction with dimmed cards */
  transform: scale(0.95); /* Slightly enlarge the expanded card */
}

.expandedCard {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow for emphasis */
  z-index: 10; /* Ensure the expanded card is above others */
}

.transactionsSection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: #ffffff; /* White background for contrast */
  z-index: 1000; /* Ensure it appears above other elements */
  overflow-y: auto; /* Enable scrolling for large content */
  padding: 20px; /* Add padding for spacing */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.transactionsRow {
  width: 100%; /* Full width of the page */
  background-color: #f9f9f914; /* Light gray background for contrast */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
  height: calc(100vh - var(--transactionsRowTop)); /* Dynamic height */
  overflow-y: auto; /* Enable scrolling for large content */
  position: relative; /* Ensure proper stacking context */
}

.merchantName {
  font-size: 1.4rem; /* Larger font for merchant name */
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for merchant name */
  margin-bottom: 5px; /* Reduce spacing below */
  text-align: center;
  width: 100%; /* Full width for better alignment */
  margin-top: 12%; /* Align to the bottom of the card */
  text-decoration: none; /* Remove underline from links */
  text-transform: capitalize; /* Capitalize the first letter of each word */
}
