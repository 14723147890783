.transactionContainer {
  width: 100%;
  max-height: calc(100vh - var(--transactionContainerTop)); /* Dynamic height */
  overflow-y: hidden; /* Prevent vertical scrolling */
  position: relative; /* Ensure the container is positioned for fixed elements */
}
.scroller {
  width: 300px;
  height: 100px;
  /*  overflow-y: scroll; */
  /*  scrollbar-width: thin; */
}
.transactionTable {
  width: 100%;
  border-collapse: collapse;
}

.transactionTable th,
.transactionTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transactionTable th {
  background-color: #4a90e2; /* Modern blue header */
  color: #ffffff; /* White text */
  text-transform: uppercase; /* Uppercase for headers */
  font-weight: bold;
  padding: 12px;
}

.transactionTable td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0; /* Light border for rows */
}

.transactionItem {
  /*   display: flex; */
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.transactionItem div {
  flex: 1;
  padding: 0 8px;
}

.transactionItem div:nth-child(1) {
  flex: 1; /* Merchant */
}

.transactionItem div:nth-child(2),
.transactionItem div:nth-child(3) {
  flex: 1; /* Transaction date and Amount */
}

.transactionItem:hover {
  background-color: #f1f5f9; /* Light hover effect */
}

.modal {
  display: flex;
  position: fixed;
  z-index: 9999; /* Ensure the modal is on top of all other elements */
  left: 0;
  top: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Prevent scrolling */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  justify-content: center; /* Center the modal horizontally */
  align-items: center; /* Center the modal vertically */
}

.modalContent {
  background-color: #ffffff; /* White background for modal */
  border-radius: 10px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Modern shadow */
  max-width: 600px;
  width: 90%;
  z-index: 10000; /* Ensure the modal content is above the modal background */
  position: relative; /* Ensure proper stacking context */
}

.close {
  color: #333333; /* Dark gray for better visibility */
  position: absolute; /* Position the close button */
  top: 10px; /* Offset from the top */
  right: 10px; /* Offset from the right */
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close:hover,
.close:focus {
  color: #e63946; /* Red on hover for emphasis */
  text-decoration: none;
}

.noTransactionsFound {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #003a70;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem; /* Larger font for better visibility */
  color: #721c24; /* Dark red for emphasis */
  background-color: #f8d7da; /* Light red background for contrast */
  padding: 20px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center the text */
  margin: 20px auto; /* Center the message */
  width: 80%; /* Restrict the width */
}

/* Mobile Styles */
@media (max-width: 600px) {
  .transactionTable tr {
    display: flex;
  }

  .transactionTable th,
  .transactionTable td {
    padding: 12px 4px;
  }

  .transactionTable th,
  .transactionTable td {
    display: block;
    text-align: left;
  }

  .transactionTable th::before,
  .transactionTable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .transactionTable th {
    background-color: transparent;
    text-align: left;
  }

  .transactionTable th::before {
    display: none;
  }

  .transactionItem {
    border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: 10px;
  }

  .transactionItem td {
    border: none;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .modalContent {
    width: 95%;
    max-width: none;
  }
}

.modalContent > h2 {
  margin-bottom: 20px;
}

@media (max-width: 999px) {
  .transactionItem div {
    padding: 0 4px;
  }

  .transactionContainer {
    width: 100%;
    overflow-x: auto;
    font-size: 12px;
  }
}

.moreInfoBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  width: 50%;
  margin: 0 auto;
  white-space: wrap;
}

.upperCase {
  text-transform: uppercase;
  font-weight: 600;
}

.lowercase {
  text-transform: lowercase;
  font-weight: lighter;
}

.additionalInfoContainer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 8px;
}

.additionalInfoRow {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0; /* Light border between rows */
  margin-bottom: 8px; /* Add spacing between rows */
  line-height: 1.5; /* Improve readability */
}

.additionalInfoKey {
  font-weight: bold;
  color: #4a90e2; /* Blue for keys */
}

.additionalInfoValue {
  color: #333333; /* Dark gray for values */
}

.transactionCards {
  display: grid; /* Use grid layout for better alignment */
  grid-template-columns: repeat(
    auto-fit,
    minmax(1200px, 1fr)
  ); /* Responsive columns */
  gap: 20px; /* Add spacing between cards */
  justify-items: center; /* Center cards horizontally */
  margin-top: 20px; /* Add spacing above the cards */
  padding: 10px; /* Add padding around the grid */
}

.transactionCard {
  display: flex; /* Use flex layout for side-by-side alignment */
  justify-content: space-between; /* Space between details and additional info */
  align-items: flex-start; /* Align items at the top */
  background-color: #f9f9f9f3; /* White background for cards */
  border: 1px solid #aeafb2; /* Light border for separation */
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Add padding for spacing */
  width: 100%; /* Full width for cards */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  gap: 20px; /* Add spacing between sections */
}

.transactionCard:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  background-color: #f5f5f5e4; /* Slightly darker background on hover */
}

.transactionDetails {
  flex: 1; /* Adjust flex to take full space */
  text-align: left; /* Align text to the left */
  padding: 10px; /* Add padding for spacing */
  display: flex; /* Use flex layout for better alignment */
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Add spacing between items */
}

.merchantName {
  font-size: 1.4rem; /* Larger font for merchant name */
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for merchant name */
  margin-bottom: 5px; /* Reduce spacing below */
}

.transactionDate,
.transactionAmount {
  font-size: 1rem; /* Standard font size for details */
  color: #7f8c8d; /* Gray for transaction details */
  margin: 0; /* Remove margins */
}

.transactionAmount {
  font-weight: bold; /* Emphasize the amount */
  color: #4a90e2; /* Blue for transaction amount */
}

.sortingContainer {
  display: flex;
  align-items: center;
  gap: 0; /* Add spacing between label and dropdown */
  position: relative; /* Ensure it stays within the card */
}

.sortingContainer.scrolled {
  gap: 0px; /* Reduce spacing for smaller size */
  font-size: 0.8rem; /* Reduce font size */
}

.sortLabel {
  font-size: 1rem;
  font-weight: bold;
  color: #2c3e50; /* Dark blue-gray for label */
  flex: unset !important; /* Remove flex behavior */
  margin-left: auto; /* Move the element to the right */
}

.sortDropdown {
  font-size: 0.4rem;
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 5px;
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Dark blue-gray text */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 10px 15px; /* Add padding for better usability */
  font-size: 1rem; /* Standard font size */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px; /* Rounded corners */
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Dark blue-gray text */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sortDropdown:hover {
  border-color: #4a90e2; /* Blue border on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.sortDropdown:focus {
  outline: none;
  border-color: #4a90e2; /* Blue border on focus */
  box-shadow: 0 0 6px rgba(74, 144, 226, 0.5); /* Glow effect on focus */
}

.searchAndSortContainer {
  position: sticky; /* Keep the container within the card */
  top: 0; /* Stick to the top of the card */
  z-index: 10; /* Ensure it stays above other elements */
  transition: all 0.3s ease; /* Smooth transition for size and position changes */
  padding: 10px 20px; /* Add padding for spacing */
  margin-top: 5px; /* Add spacing above the container */
  font-size: 1rem; /* Standard font size */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Add spacing between search and sort */
  margin-bottom: 20px; /* Add spacing below the container */
  background-color: #568bc6; /* White background for contrast */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
}

.sortLabel {
  font-size: 1.3rem;
  font-weight: 450; /* Bold for emphasis */
  color: #ffffff; /* Dark blue-gray for label */
}

.sortDropdown {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 5px;
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Dark blue-gray text */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 10px 15px; /* Add padding for better usability */
  font-size: 1rem; /* Standard font size */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 8px; /* Rounded corners */
  background-color: #ffffff; /* White background */
  color: #2c3e50; /* Dark blue-gray text */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sortDropdown:hover {
  border-color: #4a90e2; /* Blue border on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.sortDropdown:focus {
  outline: none;
  border-color: #4a90e2; /* Blue border on focus */
  box-shadow: 0 0 6px rgba(74, 144, 226, 0.5); /* Glow effect on focus */
}

.searchInput {
  margin-top: 5px; /* Add spacing above the input */
  border: 1px solid #cccccc; /* Light border for separation */
  border-radius: 8px; /* Rounded corners for modern look */
  transition: all 0.3s ease; /* Smooth transitions for all properties */
  font-size: 1.2rem; /* Slightly smaller font size */
  padding: 8px; /* Reduce padding for a smaller appearance */
}

.searchInput:focus {
  padding: 8px; /* Reduce padding for a smaller appearance */
  font-size: 1.2rem; /* Slightly smaller font size */
  border-color: #4a90e2; /* Blue border for emphasis */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for smaller size */
  outline: none; /* Remove default outline */
  border-color: #4a90e2; /* Blue border for emphasis */
  box-shadow: 0 0 6px rgba(74, 144, 226, 0.5); /* Glow effect on focus */
  outline: none; /* Remove default outline */
}

.searchInput::placeholder {
  color: #999999; /* Light gray placeholder text */
  font-style: italic; /* Italic style for placeholder */
}

/* Adjustments for mobile devices */
@media (max-width: 768px) {
  .searchInput {
    font-size: 0.9rem; /* Slightly smaller font size for smaller screens */
    padding: 8px; /* Adjust padding for smaller screens */
  }

  .searchInput:focus {
    padding: 4px; /* Further reduce padding on focus for smaller screens */
    font-size: 0.8rem; /* Smaller font size on focus for smaller screens */
  }
}

.accountHeader {
  flex: 1; /* Default flex value */
  transition: flex 0.3s ease; /* Smooth transition for flex changes */
}

/* Adjust width for larger screens */
@media (min-width: 1100px) {
  .accountHeader {
    flex: 2; /* Increase width for larger screens */
  }
}

@media (min-width: 1400px) {
  .accountHeader {
    flex: 3; /* Further increase width for very large screens */
  }
}

@media (min-width: 1800px) {
  .accountHeader {
    flex: 4; /* Maximum width for ultra-wide screens */
  }
}

.accountCard {
  flex: 1; /* Allow the card to grow equally */
  min-width: 300px; /* Set a minimum width */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  transition: flex 0.3s ease, max-width 0.3s ease; /* Smooth transition for resizing */
}

/* Adjust width for larger screens */
@media (min-width: 1100px) {
  .accountCard {
    min-width: 600px; /* Increase the minimum width for larger screens */
  }
}

@media (min-width: 1400px) {
  .accountCard {
    min-width: 500px; /* Further increase the minimum width for very large screens */
  }
}

@media (min-width: 1800px) {
  .accountCard {
    min-width: 600px; /* Maximum width for ultra-wide screens */
  }
}

.expandedCard {
  flex: 1; /* Allow the card to grow equally */
  min-width: 300px; /* Set a minimum width */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  transition: flex 0.3s ease, min-width 0.3s ease; /* Smooth transition for resizing */
}

/* Adjust width for larger screens */
@media (min-width: 1100px) {
  .expandedCard {
    min-width: 400px; /* Increase the minimum width for larger screens */
  }
}

@media (min-width: 1400px) {
  .expandedCard {
    min-width: 500px; /* Further increase the minimum width for very large screens */
  }
}

@media (min-width: 1800px) {
  .expandedCard {
    min-width: 600px; /* Maximum width for ultra-wide screens */
  }
}
