body {
  margin: 0;
  background: linear-gradient(
      135deg,
      rgba(0, 64, 128, 0.8),
      rgba(0, 102, 204, 0.8)
    ),
    url("../assets/pattern.png"); /* Add pattern overlay with lighter gradient */
  background-size: cover, cover; /* Ensure the pattern is properly sized */
  background-blend-mode: overlay; /* Blend the gradient and pattern */
  /* LITGHER */
  background-color: rgb(240, 240, 240, 0.5);
  color: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  margin-top: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInDown 1s ease-in-out;
  flex-direction: column; /* Ensure items are centered vertically */
}

header img.logo {
  width: 100px; /* Adjust the width as necessary */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px; /* Space between logo and text */
}

header h1 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 2rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  color: #ffd700; /* Change text color */
  background: -webkit-linear-gradient(#fff, #ffd700); /* Gradient text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap: break-word; /* Ensure text wraps properly */
}

.header-center {
  text-align: center;
  padding: 0;
}

.header-text {
  font-size: 1.5em;
  font-weight: 500;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  margin-top: 1rem;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 2rem;
  background: linear-gradient(
    135deg,
    rgba(0, 64, 128, 0.865),
    rgba(0, 102, 204, 0.8)
  );
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  width: 100%;
  padding-bottom: 4rem;
  padding-top: 4rem;
  margin-bottom: 4.5rem;
}

.hero-content {
  max-width: 100%;
}

.hero h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  margin-bottom: 1.5rem;
  color: white;
}

.hero p {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  color: #ffffff;
  margin: 0 auto 3rem auto;
  line-height: 1.5;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.3px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: #e2e8f0;
  margin-bottom: 2rem;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.cta {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px #0003;
  color: #004080;
  display: inline-block;
  font-size: 1.6em;
  font-weight: 700;
  padding: 20px 50px;
  text-decoration: none;
  transition: 0.3s;
}

.cta:hover {
  background: #f0f0f0;
  box-shadow: 0 10px 20px #00000040;
  transform: translateY(-5px);
}

.cta:active {
  transform: translateY(1px);
}

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  margin-bottom: 80px;
  animation: fadeInUp 1s ease-in-out 0.3s;
  justify-content: center; /* Center items horizontally */
  align-items: flex-start; /* Align items to the start vertically */
  padding: 0;
}

.feature {
  /* background: rgba(255, 255, 255, 0.1); */
  background: linear-gradient(
    135deg,
    rgba(0, 64, 128, 0.8),
    rgba(0, 102, 204, 0.8)
  );
  padding: 40px;
  border-radius: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  cursor: default;
}

.feature:hover {
  transform: translateY(-10px); /* Move up by 10px on hover */
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

.feature h3 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: 700;
}

.feature p {
  font-size: 1.2em;
  line-height: 1.7;
  flex-grow: 1; /* This will make the paragraph take up remaining space */
  min-height: 10vw;
}

.pricing-content {
  width: 60%;
  margin: 0 auto;
  height: 90%;
  background: #ffffff1a;
  border-radius: 15px;
  box-shadow: 8px 16px 55px #0003;
  padding: 60px;
  margin-top: 2.5rem;
}

@media screen and (max-width: 1200px) {
  .pricing-content {
    width: 90%;
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }
  .pricing {
    padding: 0;
    width: 95% !important;
    margin: 0 auto;
  }
  .pricing-content {
    width: 70%;
  }

  ul {
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    line-height: 1.2;
    font-weight: 300;
  }

  li {
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    line-height: 1.2;
    font-weight: 300;
  }
}

@media screen and (max-width: 480px) {
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw !important;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
  }

  .header-text {
    font-size: 1.2rem;
  }

  .header-center {
    margin: 0 !important;
    padding: 0;
  }

  .hero-content {
    text-align: center;
    margin-top: 0 !important;
  }

  .hero h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .hero p {
    font-size: 0.9rem !important;
    padding: 0;
    margin: 1rem auto;
    line-height: 1.6;
    text-align: center;
    max-width: 90%;
    margin-bottom: 1.5rem !important;
  }

  .features {
    width: 100vw;
    margin: 0 auto !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px !important;
  }

  .feature {
    width: 90%;
    margin: 0 auto;
    padding: 0.8rem;
  }

  .feature h3 {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }

  .feature p {
    font-size: 1rem;
    line-height: 1.4;
    min-height: auto;
    text-align: center;
  }

  .pricing {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing-content {
    margin: 0 auto !important;
    height: 90% !important;
    width: 100% !important;
  }

  .pricing h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .pricing p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .pricing ul {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    padding: 0;
    width: 100%;
  }

  .pricing li {
    padding: 1rem;
    margin: 0.5rem auto;
    width: 90%;
  }

  .cta {
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
    margin: 0 auto;
  }

  .hero-cta {
    gap: 0.75rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.pricing {
  margin: 0 auto;
  animation: fadeInUp 1s ease-in-out 0.6s;
  background: #ffffff1a;
  border-radius: 15px;
  box-shadow: 8px 16px 55px #0003;
  padding: 60px;
  text-align: center;
  padding-bottom: 100px;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.1); */

  background: linear-gradient(
    135deg,
    rgba(0, 64, 128, 0.8),
    rgba(0, 102, 204, 0.8)
  );
  clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 100%);

  padding-top: 100px;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeInUp 1s ease-in-out 0.6s;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .pricing {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 768px) {
  .pricing {
    padding: 60px 30px;
    clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 100%);
  }
}

@media screen and (max-width: 480px) {
  .pricing {
    padding: 40px 20px;
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
  }
}

.pricing h2 {
  font-size: 2.8em;
  color: #ffd700;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

.pricing p {
  font-size: 1.5em;
  line-height: 1.7;
  margin-bottom: 50px;
}

.pricing ul {
  list-style: none;
  font-size: 1.6em;
  padding: 0;
  margin-bottom: 50px;
}

.pricing li {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px;
  margin: 15px 0;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing li:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

footer {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin-top: auto;
  animation: fadeInUp 1s ease-in-out 0.9s;
  font-size: 0.9em;
}

/* Pricing table styles */
.pricing-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.pricing-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem; /* Add bottom margin */
}

.pricing-row:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  animation: hoverAnimation 0.3s ease-in-out; /* Add hover animation */
}

.pricing-feature {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffd700;
}

.pricing-cost {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffd700;
}

/* Media Queries */
@media (max-width: 768px) {
  header h1 {
    font-size: 2.5em;
  }

  .hero p {
    font-size: 1rem;
    padding: 0 1rem;
  }
  .cta {
    padding: 0.875rem 2rem;
    font-size: 1rem;
  }
  .features {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .feature h3 {
    font-size: 1.8em;
  }
  .feature p {
    font-size: 1.1em;
  }
  .pricing {
    clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
    padding: 40px;
    width: 100% !important;
  }
  .pricing h2 {
    font-size: 2.5em;
  }
  .pricing-table {
    gap: 0.5rem;
  }
  .pricing-row {
    padding: 0.75rem;
  }
  .pricing-feature,
  .pricing-cost {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  html {
    width: 100%;
    overflow-x: hidden;
    margin: 0 0;
    padding: 0;
  }

  body {
    width: 100%;
  }
  header {
    padding: 0;
    margin: 0;
    margin-top: 1rem !important;
  }

  .hero {
    margin-bottom: 0%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }
  .header-center {
    padding: 0;
    margin: 0;
  }

  .header-text {
    font-size: 1em;
    margin-top: 2rem;
  }

  .hero p {
    font-size: 1.2em;
    font-weight: 300;
    margin: 0;
    line-height: 1.2;
  }

  .cta {
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .features {
    margin: 10px 0;
  }
  .feature h3 {
    font-size: 1.6em;
  }
  .feature p {
    font-size: 1em;
  }
  .pricing h2 {
    font-size: 2.2em;
  }
  .pricing {
    clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
    padding: 40px;
    width: 100%;
  }
  .pricing p,
  .pricing ul,
  .pricing li {
    font-size: 1.2em;
  }
  .pricing-table {
    gap: 0.5rem;
  }
  .pricing-row {
    padding: 0.5rem;
  }
  .pricing-feature,
  .pricing-cost {
    font-size: 0.9rem;
  }
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hoverAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-5px);
  }
}

.logo {
  width: 150px; /* Adjust the width as necessary */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center the logo */
  padding: 10px 0; /* Add some padding */
}

/* Add these media queries at the end of your existing CSS */

@media screen and (max-width: 768px) {
  .features {
    padding: 3rem 1rem;
    gap: 30px;
  }

  .feature {
    flex: 1 1 100%;
    margin: 0 1rem;
  }

  .feature h3 {
    font-size: 1.5em;
  }

  .feature p {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 480px) {
  .features {
    padding: 2rem 1rem;
    gap: 20px;
  }

  .feature {
    padding: 25px;
  }

  .feature h3 {
    font-size: 1.3em;
    margin-bottom: 15px;
  }

  .feature p {
    font-size: 1em;
    line-height: 1.5;
  }
}

/* Update media queries for better mobile support */

@media screen and (max-width: 500px) {
  .features {
    padding: 2rem 0.5rem;
    gap: 15px;
    margin-bottom: 40px;
    width: 100%;
    height: 100%;
  }

  .feature {
    flex: 0 0 100%; /* Don't allow growing or shrinking */
    max-width: calc(100%- 20px); /* Account for margins */
    margin: 10px;
    padding: 25px 20px;
  }

  .feature h3 {
    font-size: 1.3em;
    margin-bottom: 12px;
  }

  .feature p {
    font-size: 1em;
    line-height: 1.5;
  }

  .pricing p,
  .pricing ul,
  .pricing li {
    font-size: 1.2em;
  }
  ul {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 0.8em !important;
    line-height: 1.2 !important;
    font-weight: 300 !important;
  }

  li {
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    line-height: 1.2;
    font-weight: 300;
  }
}

/* Extra small devices */

@media screen and (max-width: 360px) {
  .features {
    padding: 1.5rem 0.25rem;
  }

  .feature {
    margin: 8px;
    padding: 20px 15px;
  }

  .feature h3 {
    font-size: 1.2em;
  }

  .pricing p,
  .pricing ul,
  .pricing li {
    font-size: 1.2em;
  }

  ul {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 0.8em !important;
    line-height: 1.2 !important;
    font-weight: 300 !important;
  }

  li {
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    line-height: 1.2;
    font-weight: 300;
  }
}
